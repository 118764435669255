import React from "react";
import Follower from "./../../images/image-2.jpg";
import {Link} from "gatsby";


function Bioblinds() {
  return (
    <div className=" bg-green-default">
      <div className="section-container">
        <div className="flex w-full flex-col items-center justify-center">
          <h1 className="heading">Bioblinds plant research hub</h1>

          <div className="bg-white px-6 py-5 md:mt-20 mt-10">
            <p className="para-graph ">
              Our Bioblinds plant research hub is an ongoing collection of
              information about tendril plants that can be used for Bioblinds.
              All following editions will also be cross-referenced with an
              open-source Google sheet. As soon as we finished a basis, this
              document can be read and edited by registered users.
            </p>

            <p className="text-lg text-black-default underline hover:no-underline duration-150 ease-linear transition-all cursor-pointer">
              <Link className="hover:text-green-default " to="/newsletter">
              You got an idea for your own Bioblinds edition? Or you want to
              contribute in our plant research? Subscribe to our newsletter to
              get access.</Link>
            </p>
          </div>
          <div>
            <div className="md:my-20 my-10">
              <img src={Follower} alt=" " width="580" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bioblinds;
